<template>
    <div>
        <div class="header-container">
            <div class="header-content">
                <div class="header-title ui huge header bold">Risk Assessment Tool</div>
            </div>
            <div class="header-action">
                <button @click="goHome" class="ui icon mini button">
                    <i class="home icon"></i>
                </button>
                <button @click="signOut" class="ui blue mini button">
                    <i class="sign-out icon"></i>
                </button>
            </div>
        </div>

        <div class="container">
            <div v-if="isLoading">Loading...</div>
            <div v-else>
                <div class="header">General Scope</div>
                <div class="form">
                    <div class="field" v-for="(field, index) in formFields" :key="index">
                        <label>{{ field }}</label>
                        <div class="ui input" v-if="field === 'Flight/Timing'">
                            <input type="date" v-model="selectedStartDate" :min="minimumStartDate" :max="maximumStartDate"
                                autocomplete="on" @input="updateFlightTiming('Flight/Timing', 'start', $event)" />
                            <input type="date" v-model="selectedEndDate" :min="minimumEndDate" autocomplete="on"
                                @input="updateFlightTiming('Flight/Timing', 'end', $event)" />

                        </div>
                        <div class="ui input" v-else>
                            <input type="text" autocomplete="on" v-model="$store.state.campaign.generalScope[field]"
                                @input="field === 'Budget' ? validateNumber($event) : null"
                                @blur="field === 'Agency Email(s)' ? onEmailInput(field, $event.target.value) : null" />
                        </div>
                    </div>
                </div>

                <div class="actions">
                    <div class="left-actions">
                        <button class="ui basic button" @click="returnCampaignDash">Cancel</button>
                    </div>
                    <div class="right-actions">
                        <button :disabled="!fieldsFilledOut" class="ui primary button"
                            @click="goToCampaignType">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
import { mapState } from 'vuex'
import { Auth } from 'aws-amplify'
import { BASE_URL } from '../helper.js';
export default {
    data() {
        return {
            formFields: [],
            isLoading: true,
            selectedStartDate: null,
            selectedEndDate: null,
            previousStartDate: null,
        }
    },
    computed: {
        ...mapState(['user', 'campaign']),
        fieldsFilledOut() {
            const areFieldsFilled = Object.values(this.$store.state.campaign.generalScope).every(value => {
                if (typeof value === 'object' && (value.start || value.end)) {
                    return true;
                }
                const isValueFilled = typeof value === 'string' && value.trim() !== '';
                if (!isValueFilled) {
                    //fill block
                }
                return isValueFilled;
            });

            return areFieldsFilled;
        },
        minimumStartDate() {
            return new Date().toISOString().slice(0, 10);
        },
        maximumStartDate() {
            return this.selectedEndDate ? this.selectedEndDate : null;
        },
        minimumEndDate() {
            return this.selectedStartDate ? this.selectedStartDate : null;
        },
    },
    mounted() {
        this.fetchQuestions();
        console.log("Initial campaign.generalScope:", this.$store.state.campaign.generalScope);
    },
    watch: {
        selectedStartDate(newStartDate) {
            if (this.selectedEndDate && new Date(newStartDate) > new Date(this.selectedEndDate)) {
                this.selectedEndDate = null;
            }
        }
    },
    methods: {
        async fetchQuestions() {
            try {
                this.isLoading = true;
                const user = await Auth.currentAuthenticatedUser();
                const userToken = user.signInUserSession.idToken.jwtToken;
                let data = {};

                if (Object.keys(this.$store.state.campaign.generalScope).length > 1) {
                    this.formFields = Object.keys(this.$store.state.campaign.generalScope)
                }
                else {
                    const headers = {
                        'Authorization': userToken,
                        'Content-Type': 'application/json'
                    }

                    try {
                        const response = await fetch(`${BASE_URL}questions`, { headers });

                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }

                        data = await response.json(); 

                        console.log(data); 
                    } catch (error) {
                        console.error('There was an error!', error);
                    }
                }
                console.log(data);
                if (data && data.questions) {
                    const generalScopeData = data.questions.find(item => item.campaign_type === 'General Scope');
                    if (generalScopeData) {
                        this.formFields = generalScopeData.questions;
                        for (let field of generalScopeData.questions) {
                            if (field === 'Flight/Timing') {
                                this.$store.commit('updateGeneralScopeField', { field: field, value: { start: '', end: '' } });
                            } else {
                                this.$store.commit('updateGeneralScopeField', { field: field, value: '' });
                            }
                        }
                    }
                } else {
                    console.log("Pending successful API call for questions.")
                }

                this.isLoading = false;
            } catch (error) {
                console.error(error);
                this.isLoading = false;
            }
        },
        onEmailInput(field, value) {
            let emailPattern = /^\S+@\S+\.\S+$/;
            let emails = value.split(',');

            if (value === '') {
                return;
            }

            for (let i = 0; i < emails.length; i++) {
                let email = emails[i].trim();
                if (!emailPattern.test(email)) {
                    this.$store.state.campaign.generalScope[field] = '';
                    alert('Please Enter Valid Email');
                    return;
                }
            }

            this.$store.state.campaign.generalScope[field] = value;
        },
        validateNumber(event) {
            let input = event.target.value;
            let number = Number(input.replace(/,/g, ''));
            if (isNaN(number)) {
                alert('Please enter a valid number');
                event.target.value = '';
            }
        },
        updateFlightTiming(field, type, event) {
            const value = event.target.value;

            if (type === 'start') {
                if (this.selectedEndDate && new Date(value) > new Date(this.selectedEndDate)) {
                    alert("Selected start date cannot be later than the end date. Please select an appropriate start date.");

                    this.selectedStartDate = "";
                    this.selectedEndDate = "";
                    return;
                } else {
                    this.selectedStartDate = value;
                }
            } else if (type === 'end') {
                this.selectedEndDate = value;
            }

            if (this.selectedStartDate && this.selectedEndDate) {
                const formattedValue = `${this.selectedStartDate} - ${this.selectedEndDate}`;
                this.$store.commit('updateGeneralScopeField', { field: field, value: formattedValue });
            }
        },
        goToCampaignType() {
            console.log(this.$store.state.campaign.generalScope);
            if (this.user) {
                console.log('User:', this.user);
            } else {
                console.log('User is not defined yet');
            }
            this.$router.push({ name: 'CampaignType' });
        },
        returnCampaignDash() {
            this.$router.push({ name: 'CampaignDashboard' })
        },
        goHome() {
            this.$router.push({ name: 'CampaignDashboard' });
        },
        async signOut() {
            console.log('signoutmethod reached');
            try {
                this.$store.commit('clearSuccessMessage');
                await Auth.signOut();
                await this.$store.dispatch('resetCampaignState');  
                this.$router.push({ name: 'CampaignDashboard' });  
            } catch (error) {
                console.log("Sign Out Failed", error);
            }
        },
    }
};
</script>
  
<style scoped>
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #f3f3f3;
    margin-top: 0px;
    margin-bottom: 20px;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.container {
    width: 100%;
}

.form {
    width: 80%;
    margin: 0 auto;
}

.field {
    width: 100%;
    margin-bottom: 20px;
}

.ui.input {
    width: 100%;
    margin-top: 10px;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
}

.left-actions,
.right-actions {
    flex-grow: 1;
}

.left-actions {
    display: flex;
    justify-content: flex-start;
}

.right-actions {
    display: flex;
    justify-content: flex-end;
}
</style>