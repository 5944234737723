<template>
  <div v-if="isLoading" class="loading-cover">
        <div class="ui active dimmer" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000;">
            <div class="ui text loader">Loading</div>
        </div>
    </div>
    <div v-else>
      <div>
    <div class="header-container">
      <div class="header-content">
        <div class="header-title ui huge header bold">Risk Assessment Tool</div>
      </div>
      <div class="header-action">
        <button @click="goHome" class="ui icon mini button">
          <i class="home icon"></i>
        </button>
        <button @click="signOut" class="ui blue mini button">
          <i class="sign-out icon"></i>
        </button>
      </div>
    </div>

    <div class="container">
      <div class="wrap-up">
        <h2>Campaign Summary</h2>

        <section>
          <h4 style="margin-left: 0;">General Scope</h4>
          <ul style="list-style: none; padding-left: 0; text-align: center;">
            <li v-for="(value, key) in generalScope" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </section>

        <section v-for="(campaignType, index) in selectedCampaignTypes" :key="index">
          <h4 style="margin-left: 0;">{{ campaignType }}</h4>
          <ul style="list-style: none; padding-left: 0; text-align: center;">
            <li v-for="(value, question) in getQuestions(campaignType)" :key="question">
              {{ question }}: {{ value }}
            </li>
          </ul>
        </section>

        <section>
          <h4 style="margin-left: 0;">Test & Learn</h4>
          <ul style="list-style: none; padding-left: 0; text-align: center;">
            <li v-for="(value, key) in testAndLearnQuestions" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </section>

        <div class="actions">
          <button class="ui basic button" @click="previousPage">Cancel</button>
          <button class="ui primary button" @click="submitCampaign">Submit</button>
        </div>
      </div>
    </div>
  </div>
    </div>

</template>

  
  
<script>
import { useStore } from 'vuex'
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { BASE_URL } from '../helper.js';

export default {
  data() {
    return {
      showModal: false,
      denialReason: '',
      isLoading: false
    }
  },
  setup() {
    const store = useStore();
    const generalScope = store.state.campaign.generalScope;
    const selectedCampaignTypes = store.state.campaign.selectedCampaignTypes;
    const testAndLearnQuestions = store.state.campaign.testAndLearnQuestions;
    const getQuestions = (campaignType) => {
      switch (campaignType) {
        case 'Paid':
          return store.state.campaign.paidQuestions;
        case 'Influencer':
          return store.state.campaign.influencerQuestions;
        case 'Organic':
          return store.state.campaign.organicQuestions;
        default:
          return {};
      }
    }

    return {
      generalScope,
      selectedCampaignTypes,
      getQuestions,
      testAndLearnQuestions
    }
  },
  methods: {
    previousPage() {
      this.$router.push({ name: 'TestAndLearn' })
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    submitDenialLog() {
      this.closeModal();
    },
    constructRequestBody() {
      let requestBody = {
        response: []
      };
      let campaignTypesMap = {
        paidQuestions: "Paid",
        generalScope: "General Scope",
        influencerQuestions: "Influencer",
        organicQuestions: "Organic",
        testAndLearnQuestions: "Test and Learn"
      };

      for (let key in campaignTypesMap) {
        let campaignItem = {
          response: [],
          campaign_type: campaignTypesMap[key]
        };

        let campaignData = this.$store.state.campaign[key];
        for (let question in campaignData) {
          let questionResponse = {
            Value: campaignData[question],
            Question: question
          };
          campaignItem.response.push(questionResponse);
        }

        if (campaignItem.response.length >= 1)
          requestBody.response.push(campaignItem);
      }

      return requestBody;
    },
    async submitCampaign() {
      this.isLoading = true;
      let requestBody = this.constructRequestBody();
      console.log(requestBody)

      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken;

      let headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      }

      try {
        let response = await axios.post(`${BASE_URL}campaigns/`, JSON.stringify(requestBody), { headers: headers });
        console.log('URL' + `${BASE_URL}/$dev/campaigns/`);
        console.log(response.data)
        this.$store.commit('setSuccessMessage', 'The campaign was submitted successfully.');
        setTimeout(() => {
            this.$store.commit('clearSuccessMessage');
        }, 3000);
        this.$router.push({ name: 'CampaignDashboard' });
      } catch (error) {
        console.log('error', error)
      }

    },
    goHome() {
      this.$router.push({ name: 'CampaignDashboard' });
    },
    async signOut() {
      console.log('signoutmethod reached');
      try {
        this.$store.commit('clearSuccessMessage');
        await Auth.signOut();
        await this.$store.dispatch('resetCampaignState');  
        this.$router.push({ name: 'CampaignDashboard' });  
      } catch (error) {
        console.log("Sign Out Failed", error);
      }
    },
  }
}
</script>
  
<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f3f3f3;
  margin-top: 0px;
  margin-bottom: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  font-size: 24px;
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

section {
  margin-bottom: 20px;
}
</style>
  