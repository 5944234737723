<template>
  <div class="ui container">
    <div v-if="isLoading" class="ui segment" style="min-height: 100vh;">
      <div class="ui active inverted dimmer">
        <div class="ui text loader">Loading</div>
      </div>
    </div>
    <div v-else style="padding: 20px;"> 
      <div class="ui secondary buttons mb-4" style="margin-bottom: 20px;"> 
        <button class="ui button" v-for="(item, index) in questions" :key="index"
          :class="{ active: item.campaign_type === selectedCampaignType }"
          @click="selectedCampaignType = item.campaign_type">
          {{ item.campaign_type }}
        </button>
      </div>

      <div v-for="(item, index) in questions" :key="index" v-show="item.campaign_type === selectedCampaignType"
        style="margin-bottom: 20px;"> 
        <h2 class="ui header">{{ item.campaign_type }}</h2>
        <div class="ui list">
          <div class="item" v-for="(question, qIndex) in item.questions" :key="qIndex">
            <div class="ui action input m-4" style="width: 100%;"> 
              <input type="text" v-model="item.questions[qIndex]" style="width: 80%;" />
              <button class="ui red icon button" @click="deleteQuestion(index, qIndex)">
                <i class="trash icon"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="ui action input mb-3" style="width: 100%;"> 
          <input type="text" v-model="newQuestions[index]" placeholder="Add new question" style="width: 80%;" />
          <button class="ui green icon button" @click="addQuestion(index)">
            <i class="plus icon"></i>
          </button>
        </div>
      </div>

      <div class="ui buttons m-4" style="margin-top: 20px;"> 
        <button class="ui button" @click="goBack">Cancel</button>
        <button class="ui primary button" @click="showModal">Submit</button>
      </div>
    </div>

    <div class="ui modal" v-show="openModal" ref="modal">
      <i class="close icon" @click="openModal = false"></i>
      <div class="header">Confirmation</div>
      <div class="content">
        <div class="ui cards">
          <div class="card" v-for="(item, index) in questions" :key="index">
            <div class="content">
              <div class="header">{{ item.campaign_type }}</div>
              <div class="description">
                <div class="ui list">
                  <div class="item" v-for="(question, qIndex) in item.questions" :key="qIndex">
                    {{ question }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>Are you sure you want to submit?</p>
      </div>
      <div class="actions">
        <button class="ui button" @click="closeModal">Cancel</button>
        <button class="ui primary button" @click="submitResponses" :disabled="isLoading">
          <span v-if="isLoading">Loading...</span>
          <span v-else>Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { Auth } from "aws-amplify";
import router from "@/router";
import localJQuery from 'jquery';
import 'semantic-ui-css/semantic.min.js';
import { BASE_URL } from '../helper.js';

const $ = localJQuery;

export default {
  data() {
    return {
      isLoading: true,
      questions: [],
      newQuestions: ["", "", "", "", ""],
      selectedCampaignType: null,
      openModal: false
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchQuestions();
    $(this.$refs.modal).modal();

  },
  methods: {
    addQuestion(index) {
      if (this.newQuestions[index]) {
        this.questions[index].questions.push(this.newQuestions[index]);
        this.newQuestions[index] = '';
      }
    },
    deleteQuestion(questionIndex, itemIndex) {
      this.questions[questionIndex].questions.splice(itemIndex, 1);
    },
    goBack() {
      this.$router.go(-1);
    },
    showModal() {
      $(this.$refs.modal).modal('show');
    },
    closeModal() {
      $(this.$refs.modal).modal('hide');
    },
    async fetchQuestions() {
      this.isLoading = true;
      const user = await Auth.currentAuthenticatedUser();
      const userToken = user.signInUserSession.idToken.jwtToken;
      const endPoint = `${BASE_URL}questions`;

      try {
        const response = await fetch(endPoint, {
          headers: {
            'Authorization': userToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.questions = data.questions;

        this.newQuestions = new Array(data.questions.length).fill("");

        if (this.questions.length > 0) {
          this.selectedCampaignType = this.questions[0].campaign_type;
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        this.isLoading = false;
      }
    },
    async submitResponses() {
      try {
        this.isLoading = true;
        const user = await Auth.currentAuthenticatedUser();
        const userToken = user.signInUserSession.idToken.jwtToken;

        const options = {
          method: 'POST',
          headers: {
            'Authorization': userToken,
            'Content-Type': 'application/json'
          },
        };

        const requestBody = {
          questions: this.questions
        };

        await fetch(`${BASE_URL}questions/`, {
          ...options,
          body: JSON.stringify(requestBody)
        });

        this.isLoading = false;

        this.closeModal();

        this.$store.commit('setSuccessMessage', 'The question set was edited successfully.');
        router.back();
      } catch (error) {
        console.error(error);
      }
    },
  }
}
</script>


<style> ui-secondary-buttons {
   padding-bottom: 20px;
 }</style>