import { createApp } from 'vue';
import App from './App.vue';
import SuiVue from 'semantic-ui-vue';
import 'semantic-ui-css/semantic.min.css';
import router from './router';
import store from './store';
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;


const app = createApp(App);
app.use(SuiVue);
app.use(router);
app.use(store);
app.mount('#app');