<template>
  <div class="header-container">
    <div class="header-content">
      <div class="header-title ui huge header bold">Risk Assessment Tool</div>
    </div>
    <div class="header-action">
      <button @click="goHome" class="ui icon mini button">
        <i class="home icon"></i>
      </button>
      <button @click="signOut" class="ui blue mini button">
        <i class="sign-out icon"></i>
      </button>
    </div>
  </div>

  <div class="ui container">
    <div v-if="isLoading" class="loading-cover">
      <div class="ui active dimmer" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000;">
        <div class="ui text loader">Loading</div>
      </div>
    </div>
    <div v-else style="padding: 20px;">

      <div class="content-container" style="display: grid; grid-template-columns: 3fr 1fr; gap: 20px;">

        <div class="questions-section">
          <div class="header">Modify Campaign</div>
          <div class="ui buttons mb-4">
            <button class="ui button" v-for="(item, index) in response" :key="index"
              @click="selectCampaignType(item.campaign_type)">
              {{ item.campaign_type }}
              <span v-if="!campaignTypesViewed.includes(item.campaign_type)">*</span>
            </button>
          </div>
          <p style="padding-top: 20px; padding-bottom: 10px;">Please review all campaign questions before re-submitting
            your request.</p>
          <div v-for="(item, index) in response" :key="index" v-show="item.campaign_type === selectedCampaignType"
            class="ui segment">
            <h2 class="ui header">{{ item.campaign_type }}</h2>
            <div class="ui form">
              <div class="field" v-for="(responseItem, rIndex) in item.response" :key="rIndex">

                <label v-if="!responseItem.Question.toLowerCase().includes('flight/timing')">
                  {{ responseItem.Question }}
                </label>

                <template v-else>
                  <label>Flight/Timing</label>

                  <div class="date-pickers" ref="datePickers">
                    <input type="date" v-model="startDate" :min="today" @input="handleStartDateChange" ref="startDate"
                      class="date-picker" />
                    <input type="date" v-model="endDate" :min="minEndDate" @input="handleEndDateChange" ref="endDate"
                      class="date-picker" />
                  </div>
                </template>
                <input v-if="!responseItem.Question.toLowerCase().includes('flight/timing')" type="text"
                  v-model="responseItem.Value" />
              </div>
            </div>
          </div>
        </div>

        <div class="denial-section">
          <div class="ui segment">
            <h5 class="ui header">Past Denial Reasons</h5>
            <div class="ui list">
              <div class="item" v-for="(reason, index) in pastDenialReasons" :key="index">
                <p><b>{{ reason.name }}</b> - {{ reason.updatedOn }} - {{ reason.reason }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons-container" style="display: flex; justify-content: center; margin-top: 20px;">
        <button class="ui red button" @click="goHome">Cancel</button>
        <button class="ui blue button" :disabled="!allCampaignTypesViewed" @click="showModal"
          style="margin-left: 10px;">Submit Changes</button>
      </div>
    </div>

    <div id="confirmationModal" class="ui modal">
      <div class="header">Confirmation</div>
      <div class="content">
        <p>Are you sure you want to submit these changes?</p>
      </div>
      <div class="actions">
        <div class="ui negative button">No</div>
        <div class="ui positive button" @click="submitResponses">Yes</div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { Auth } from "aws-amplify";
import localJQuery from 'jquery';
import 'semantic-ui-css/semantic.min.js';
import { BASE_URL } from '../helper.js';

const $ = localJQuery;

export default {
  async mounted() {
    this.campaignId = this.$route.params.campaignId;

    const localStorageData = localStorage.getItem(`campaignData-${this.campaignId}`);
    if (localStorageData) {
      try {
        this.response = JSON.parse(localStorageData);

        if (this.response && this.response.length > 0) {
          this.selectedCampaignType = this.response[0].campaign_type;
          this.campaignTypesViewed.push(this.response[0].campaign_type);
        }
        this.isLoading = false;
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
        await this.fetchCampaignData();
      }
    } else {
      await this.fetchCampaignData();
    }

    await this.fetchResponseData();
    //console.log('Campaign dates:', this.campaignDates);
  },
  watch: {
    '$route': {
      immediate: true,
      async handler() {
        const localStorageData = localStorage.getItem(`campaignData-${this.campaignId}`);
        if (localStorageData) {
          try {
            this.response = JSON.parse(localStorageData);

            if (this.response && this.response.length > 0) {
              this.selectedCampaignType = this.response[0].campaign_type;
              this.campaignTypesViewed.push(this.response[0].campaign_type);
            }
            this.isLoading = false;
          } catch (error) {
            console.error("Error parsing JSON from localStorage:", error);
            await this.fetchCampaignData();
          }
        } else {
          await this.fetchCampaignData();
        }

        await this.fetchResponseData();
      }
    },
    response: {
      handler(newValue) {
        localStorage.setItem(`campaignData-${this.campaignId}`, JSON.stringify(newValue));
      },
      deep: true
    }
  },
  computed: {
    allCampaignTypesViewed() {
      return this.response.every(item => this.campaignTypesViewed.includes(item.campaign_type));
    },
    campaignDates() {
      if (this.campaign.details && this.campaign.details.dateRange) {
        return this.campaign.details.dateRange;
      }
      return null;
    },
  },
  data() {
    return {
      campaignId: null,
      isLoading: true,
      selectedCampaignType: '',
      response: [],
      pastDenialReasons: [],
      campaignTypesViewed: [],
      campaignFetched: false,
      startDate: null,
      endDate: null,
      minEndDate: null,
      today: new Date().toISOString().slice(0, 10)
    }
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'CampaignDashboard' });
    },
    handleStartDateChange() {
      console.log("Initial start date: ", this.startDate);
      if (this.endDate && new Date(this.startDate) > new Date(this.endDate)) {
        this.endDate = null;
      }
      this.minEndDate = this.startDate;
      console.log("Final start date: ", this.startDate);
    },

    handleEndDateChange() {
      console.log("Initial end date: ", this.endDate);
      if (this.startDate && new Date(this.endDate) < new Date(this.startDate)) {
        this.startDate = null;
      }
      console.log("Final end date: ", this.endDate);
    },
    beforeSubmit() {
      this.response.forEach(item => {
        item.response.forEach(responseItem => {
          if (responseItem.Question.toLowerCase().includes('flight/timing')) {
            const startDate = new Date(this.startDate);
            const endDate = new Date(this.endDate);

            // Get the dates in 'YYYY-MM-DD' format
            const formattedStartDate = startDate.toISOString().slice(0, 10);
            const formattedEndDate = endDate.toISOString().slice(0, 10);

            responseItem.Value = `${formattedStartDate} - ${formattedEndDate}`;
            console.log("Value for 'flight/timing': ", responseItem.Value);
          }
        });
      });
    },
    async signOut() {
      console.log('signoutmethod reached');
      try {
        this.$store.commit('clearSuccessMessage');
        await Auth.signOut();
        await this.$store.dispatch('resetCampaignState');
        this.$router.push({ name: 'CampaignDashboard' });
      } catch (error) {
        console.log("Sign Out Failed", error);
      }
    },
    selectCampaignType(campaignType) {
      this.selectedCampaignType = campaignType;
      if (!this.campaignTypesViewed.includes(campaignType)) {
        this.campaignTypesViewed.push(campaignType);
      }
    },
    showModal() {
      $('#confirmationModal').modal('show');
    },

    async submitResponses() {
      this.beforeSubmit();
      console.log(this.response);
      this.isLoading = true;
      const user = await Auth.currentAuthenticatedUser();
      const userToken = user.signInUserSession.idToken.jwtToken;

      const options = {
        method: 'POST',
        headers: {
          'Authorization': userToken,
          'Content-Type': 'application/json'
        },
      };

      const requestBody = {
        response: this.response,
        id: this.campaignId
      };
      console.log(requestBody);

      const res = await fetch(`${BASE_URL}campaigns/modify`, {
        ...options,
        body: JSON.stringify(requestBody)
      });
      console.log(res)
      localStorage.removeItem('campaignData');
      $('#confirmationModal').modal('hide');
      this.$store.commit('setSuccessMessage', 'The campaign was modified and submitted successfully.');
      setTimeout(() => {
        this.$store.commit('clearSuccessMessage');
      }, 3000);
      router.back();
    },
    isValidDate(d) {
      return !isNaN(Date.parse(d));
    },
    async fetchCampaignData() {
      console.log('fetchCampaignData method called');
      const user = await Auth.currentAuthenticatedUser();
      const userToken = user.signInUserSession.idToken.jwtToken;
      const endPoint = `${BASE_URL}campaigns/` + this.campaignId;

      try {
        const response = await fetch(endPoint, {
          headers: {
            'Authorization': userToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        this.campaignFetched = true;

        if (data.campaigns && data.campaigns.response) {
          this.response = data.campaigns.response;
        } else {
          this.response = [];
        }

        if (this.response.length > 0) {
          this.selectedCampaignType = this.response[0].campaign_type;
          this.campaignTypesViewed.push(this.response[0].campaign_type);
          for (let item of this.response) {
            for (let responseItem of item.response) {
              if (responseItem.Question.toLowerCase().includes('flight/timing')) {
                const dates = responseItem.Value.split(' - ');

                if (this.isValidDate(dates[0]) && this.isValidDate(dates[1])) {
                  this.startDate = new Date(dates[0]).toISOString().slice(0, 10);
                  this.endDate = new Date(dates[1]).toISOString().slice(0, 10);
                } else {
                  this.startDate = null;
                  this.endDate = null;
                }
                console.log("Fetched start date: ", this.startDate);
                console.log("Fetched end date: ", this.endDate);
              }
            }
          }
        }
        this.isLoading = false;
      } catch (error) {
        console.error('error', error);
      }
    },
    async fetchResponseData() {
      const user = await Auth.currentAuthenticatedUser();
      const userToken = user.signInUserSession.idToken.jwtToken;
      const endPoint = `${BASE_URL}denial_reasons/` + this.campaignId;

      try {
        const response = await fetch(endPoint, {
          headers: {
            'Authorization': userToken,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.pastDenialReasons = data.denial_reasons.map(item => {
          const name = item.approver_name?.toString() || '';
          const updatedOn = new Date(parseInt(item.updated_on) * 1000).toLocaleDateString() || '';
          const reason = item.reason?.toString() || '';

          return { name, updatedOn, reason };
        });
      } catch (error) {
        console.error('error', error);
      }
    }
  }
}
</script>

<style scoped>
.date-pickers {
  display: flex;
  gap: 10px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f3f3f3;
  margin-top: 0px;
  margin-bottom: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tab-btn {
  background-color: #f8f8f8;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.question-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card {
  border-radius: 15px;
}

.btn-outline-primary.active {
  background-color: #007bff;
  color: white;
}

.denial-reasons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  /* Adjust according to your needs */
}
</style>
