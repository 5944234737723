<template>
  <div>
    <div class="header-container">
      <div class="header-content">
        <div class="header-title ui huge header bold">Risk Assessment Tool</div>
        <div class="welcome-message">
          Welcome, {{ user && user.attributes ? user.attributes.name : 'Loading...' }}!
        </div>
      </div>
      <div class="header-action">
        <button v-if="role === 'approvers'" class="ui green mini button" @click="goToEditor">Edit Questions</button>
        <router-link to="general-scope">
          <button v-if="role === 'campaignPlanner'" @click="resetStore"
            class="ui blue mini button new-campaign-button">New Campaign</button>
        </router-link>
        <button @click="signOut" class="ui blue mini button sign-out-button">
          <i class="sign-out icon"></i>
        </button>
      </div>
    </div>
    <div class="ui blue message">
      <div class="header">MARS Brand Safety SteerCo</div>
      <p>
        Some housekeeping and purpose of this template: TikTok as a platform still poses a high risk for our brand
        safety and suitability, therefore we make careful decisions about how we engage to mitigate the risk. Please
        refer to the TikTok Brand Safety Guidelines (available on the Brand Safety SharePoint here) to understand the
        ways, guardrails and process within which we can leverage TikTok. All TikTok activity needs to be flagged to
        and approved by the Brand Safety Steer Co (Jacqui Stephenson, Tony Squires, Jelena Jovanovic, and Kristin Heume) both at concept
        stage and before go-live. Please refer to the TikTok Brand Safety Guidelines for the full process. This
        template supports sharing the final activity set-up before go-live with the Steer Co (step 5 of the process).
        Please complete it and send to the Brand Safety Steer Co for review.
      </p>
    </div>
    <div class="ui success message" v-if="successMessage">
      <i class="close icon" @click="closeSuccessMessage"></i>
      <div class="header">{{ successMessage }}</div>
    </div>
    <table class="ui fixed single line striped celled table">
      <thead>
        <tr>
          <th>Campaign Name</th>
          <th>Planner</th>
          <th style="width: 10%; text-align: center;">Status</th>
          <th style="width: 10%;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="4">
            <div class="ui segment">
              <div class="ui active dimmer">
                <div class="ui loader"></div>
              </div>
            </div>
          </td>
        </tr>
        <tr v-else v-for="campaign in campaigns" :key="campaign.id">
          <td>{{ campaign.name }}</td>
          <td>{{ campaign.created_by }}</td>
          <td style="text-align: center;" class="aligned">
            <div class="ui label" :class="getStatusClass(campaign.status)">{{ campaign.status }}</div>
          </td>
          <td style="text-align: center;">
            <div class="ui icon basic buttons">
              <a v-if="campaign.status !== 'Approved' && role !== 'approvers'" class="ui mini button circular"
                @click="goToReview(campaign.id)">
                <i class="edit icon"></i>
              </a>
              <a class="ui mini button circular" @click="goToCampaignDetails(campaign.id)">
                <i class="arrow right icon"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination" v-if="totalPages > 0">
      <button v-for="page in totalPages" :key="page" :class="{ active: page === currentPage }" @click="changePage(page)"
        class="ui blue mini button">
        {{ page }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { Auth } from 'aws-amplify';
import { BASE_URL } from '../helper.js';
export default {
  name: 'CampaignDashboard',
  data() {
    return {
      user: {},
      role: '',
      campaigns: [],
      loading: false,
      lastEvaluatedKey: null,
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
    };
  },
  computed: {
    ...mapState(['successMessage']),
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  watch: {
    successMessage: {
      handler(newVal) {
        console.log("Watcher triggered with value: ", newVal);
        if (newVal) {
          setTimeout(() => {
            this.$store.commit('clearSuccessMessage');
          }, 3000);
        }
      },
      immediate: false, 
    },
  },
  methods: {
    ...mapMutations(['clearSuccessMessage']),
    ...mapActions(['resetCampaignState']),
    async fetchData(page = 1) {
      this.loading = true;

      try {
        const params = new URLSearchParams();
        params.append("limit", this.itemsPerPage.toString());

        if (this.lastEvaluatedKey && page > 1) {
          params.append("exclusiveStartKey", this.lastEvaluatedKey);
        }

        const response = await fetch(`${BASE_URL}campaigns?${params.toString()}`, {
          headers: {
            'Authorization': this.user.signInUserSession.idToken.jwtToken
          }
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();

        this.campaigns = data.campaigns;
        this.totalItems = data.totalRecords;
        this.lastEvaluatedKey = data.lastEvaluatedKey;

      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false;
      }
    }
    ,
    changePage(page) {
      this.currentPage = page;
      this.fetchData(page);
    },
    async resetStore() {
      await this.resetCampaignState();
    },
    getStatusClass(status) {
      switch (status) {
        case 'Pending':
          return 'orange';
        case 'Denied':
          return 'red';
        case 'Approved':
          return 'green';
        default:
          return '';
      }
    },
    async signOut() {
      try {
        this.clearSuccessMessage();
        await Auth.signOut();
        await this.resetCampaignState();
        this.$router.push({ name: 'CampaignDashboard' });
      } catch (error) {
        console.log('Sign Out Failed', error);
      }
    },
    closeSuccessMessage() {
      this.clearSuccessMessage();
    },
    goToCampaignDetails(campaignId) {
      this.$router.push({ name: 'CampaignSummary', params: { campaignId } });
    },
    goToEditor() {
      this.$router.push('/question-editor');
    },
    goToReview(campaignId) {
      this.$router.push({ name: 'ReviewCampaign', params: { campaignId } });
    },
  },
  async mounted() {
    this.user = await Auth.currentAuthenticatedUser();
    this.role = this.user.signInUserSession.idToken.payload['cognito:groups']?.[0];
    if (!this.role || this.role != 'approvers') {
      this.role = 'campaignPlanner';
    }
    this.fetchData(1);
  },
};
</script>

<style scoped>
.pagination {
  text-align: center;
  margin: 20px 0;
}

.pagination .ui.blue.mini.button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: black;
  color: white;
  cursor: pointer;
}

.pagination .ui.blue.mini.button.active {
  background-color: black;
  color: white;
}
.header-action .new-campaign-button {
  margin-left: 15px;
  background-color: #ff0050;; 
  color: white;
}

.header-action .sign-out-button {
  margin-left: 15px;
  background-color: black; 
  color: white;
}

.message {
  width: 100%;
}

.loading-segment {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f3f3f3;
  margin-top: 0;
  margin-bottom: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.welcome-message {
  margin-top: 0;
}

.header-action {
  display: flex;
  align-items: center;
}

.ui.button {
  margin-left: 15px;
}

.ui.icon.button {
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.ui.icon.button:hover {
  opacity: 1;
}

.label {
  width: 60%;
}
</style>
