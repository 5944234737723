<template>
    <div>
        <div class="header-container">
            <div class="header-content">
                <div class="header-title ui huge header bold">Risk Assessment Tool</div>
            </div>
            <div class="header-action">
                <button @click="goHome" class="ui icon mini button">
                    <i class="home icon"></i>
                </button>
                <button @click="signOut" class="ui blue mini button">
                    <i class="sign-out icon"></i>
                </button>
            </div>
        </div>

        <div class="container">
            <div v-if="isLoading">Loading...</div>
            <div v-else>
                <div class="header">Concept Specifics: Organic</div>

                <div class="form">
                    <div class="field" v-for="(field, index) in formFields" :key="index">
                        <label>{{ field }}</label>
                        <div class="ui input">
                            <input type="text" v-model="this.$store.state.campaign.organicQuestions[field]" />
                        </div>
                    </div>
                </div>

                <div class="actions">
                    <div class="left-actions">
                        <button class="ui basic button" @click="previous">Cancel</button>
                    </div>
                    <div class="right-actions">
                        <button :disabled="!fieldsFilledOutOrganicQuestions" class="ui primary button" @click="next">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { Auth } from 'aws-amplify';
import { nextPage, previousPage, BASE_URL} from '../helper.js';
export default {
    data() {
        return {
            formFields: []
        }
    },
    computed: {
        fieldsFilledOutOrganicQuestions() {
            return Object.values(this.$store.state.campaign.organicQuestions).every(value => value !== '');
        },
    },
    mounted() {
        this.fetchQuestions();
    },
    methods: {
        async fetchQuestions() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const userToken = user.signInUserSession.idToken.jwtToken;
                let data = {};
                if (Object.keys(this.$store.state.campaign.organicQuestions).length > 1) {
                    this.formFields = Object.keys(this.$store.state.campaign.organicQuestions)
                } else {
                    const headers = {
                        'Authorization': userToken,
                        'Content-Type': 'application/json'
                    }

                    try {
                        const response = await fetch(`${BASE_URL}questions`, { headers });

                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }

                        data = await response.json(); 

                        console.log(data); 
                    } catch (error) {
                        console.error('There was an error!', error);
                    }
                    const organicQuestions = data.questions.find(item => item.campaign_type == 'organic');

                    if (organicQuestions) {
                        this.formFields = organicQuestions.questions;

                        for (let field of organicQuestions.questions) {
                            this.$store.commit('updateOrganicQuestionsScope', { field: field, value: '' });
                        }
                    }
                }
            } catch (error) {
                console.log('catch error hit')
            }
        },
        next() {
            console.log('nextpage method is hitting')
            const selectedCampaignTypes = this.$store.state.campaign.selectedCampaignTypes;
            const currentCampaignType = 'Organic';
            console.log(currentCampaignType);

            nextPage(selectedCampaignTypes, currentCampaignType, this.$router);
        },
        previous() {
            const selectedCampaignTypes = this.$store.state.campaign.selectedCampaignTypes;
            const currentCampaignType = 'Organic';

            previousPage(selectedCampaignTypes, currentCampaignType, this.$router);
        },
        goHome() {
            this.$router.push({ name: 'CampaignDashboard' });
        },
        async signOut() {
            console.log('signoutmethod reached');
            try {
                this.$store.commit('clearSuccessMessage');
                await Auth.signOut();
                await this.$store.dispatch('resetCampaignState');  
                this.$router.push({ name: 'CampaignDashboard' });  
            } catch (error) {
                console.log("Sign Out Failed", error);
            }
        },
    }
};
</script>

  
<style scoped>
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #f3f3f3;
    margin-top: 0px;
    margin-bottom: 20px;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.container {
    width: 100%;
}

.form {
    width: 80%;
    margin: 0 auto;
}

.field {
    width: 100%;
    margin-bottom: 20px;
}

.ui.input {
    width: 100%;
    margin-top: 10px;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
}

.left-actions,
.right-actions {
    flex-grow: 1;
}

.left-actions {
    display: flex;
    justify-content: flex-start;
}

.right-actions {
    display: flex;
    justify-content: flex-end;
}
</style>