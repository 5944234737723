export const BASE_URL = 'https://3nmm82l59g.execute-api.us-east-1.amazonaws.com/dev/'

export function nextPage(selectedCampaignTypes, currentCampaignType, router) {
    const CAMPAIGN_QUESTION_ORDERING = ['Paid', 'Influencer', 'Organic'];
    const currentIndex = CAMPAIGN_QUESTION_ORDERING.indexOf(currentCampaignType);
    console.log(selectedCampaignTypes);
    console.log(currentIndex+1)
    for (let i = currentIndex + 1; i < CAMPAIGN_QUESTION_ORDERING.length; i++) {
        if (selectedCampaignTypes.includes(CAMPAIGN_QUESTION_ORDERING[i])) {
            let newRoute = CAMPAIGN_QUESTION_ORDERING[i].toLowerCase();
            console.log(newRoute);
            router.push(`/${newRoute}`)
            return;
        }
    }
    router.push('/test-and-learn')
}

export function previousPage(selectedCampaignTypes, currentCampaignType, router) {
    const CAMPAIGN_QUESTION_ORDERING = ['Paid', 'Influencer', 'Organic'];
    const currentIndex = CAMPAIGN_QUESTION_ORDERING.indexOf(currentCampaignType);

    for (let i = currentIndex - 1; i > -1; i--) {
        if (selectedCampaignTypes.includes(CAMPAIGN_QUESTION_ORDERING[i])) {
            let newRoute = CAMPAIGN_QUESTION_ORDERING[i].toLowerCase();
            router.push(`/${newRoute}`)
            return;
        }
    }
    router.push('/campaign-type')
}



