<template>
    <div v-if="isLoading" class="loading-cover">
        <div class="ui active dimmer" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1000;">
            <div class="ui text loader">Loading</div>
        </div>
    </div>
    <div v-else>
        <div class="header-container">
            <div class="header-content">
                <div class="header-title ui huge header bold">Risk Assessment Tool</div>
            </div>
            <div class="header-action">
                <button @click="goHome" class="ui icon mini button">
                    <i class="home icon"></i>
                </button>
                <button @click="signOut" class="ui blue mini button">
                    <i class="sign-out icon"></i>
                </button>
            </div>
        </div>
        <div class="ui container" style="margin-top: 30px; max-width: 1000px;">
            <div class="ui grid stackable">
                <div class="sixteen wide column">
                    <div v-if="campaignInfo.campaigns">
                        <div class="ui card fluid">
                            <div class="content">
                                <div class="header">Campaign Summary</div>
                            </div>
                            <div class="content" style="word-break: break-word;">
                                <div class="header">{{ campaignInfo.campaigns.name }}</div>
                                <p>Created By: {{ campaignInfo.campaigns.created_by }}</p>
                            </div>
                        </div>
                        <div v-for="(campaignType, index) in campaignInfo.campaigns.response" :key="index"
                            class="ui card fluid">
                            <div class="content" style="word-break: break-word;">
                                <div class="header">{{ campaignType.campaign_type }}</div>
                                <ul class="ui list custom-list">
                                    <li v-for="response in campaignType.response" :key="response.Question">
                                        <b>{{ response.Question }}: </b> {{ response.Value }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sixteen wide column">
                    <div class="ui card fluid" style="margin-bottom: 25px;">
                        <div class="content">
                            <div class="header">Past Denial Reasons</div>
                            <div class="description">
                                <ul class="ui list">
                                    <li v-for="(reason, index) in pastDenialReasons" :key="index">
                                        <b>{{ reason.name }}</b> - {{ reason.updatedOn }} - {{ reason.reason }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button class="ui negative basic button" @click="previousPage">Cancel</button>
                <button v-if="role === 'approvers'" class="ui red basic button" @click="denyCampaign">Deny</button>
                <button v-if="role === 'approvers'" class="ui green basic button" @click="acceptCampaign">Accept</button>
            </div>
            <EmailModal v-model="showModal" :emails="emails" @close="closeEmailModal" @submit="handleEmailSubmit" />
            <DenialModal :show="showDenialModal" @close="closeDenialModal" @submit="handleDenial" />
        </div>
    </div>
</template>




<script>
import { Auth } from 'aws-amplify';
import { BASE_URL } from '../helper.js';
import EmailModal from './AcceptModal.vue'
import DenialModal from './DenialModal.vue'
import axios from 'axios';
import $ from 'jquery'
window.$ = window.jQuery = $;

export default {
    data() {
        return {
            campaignId: null,
            role: '',
            campaignInfo: {},
            showModal: false,
            showDenialModal: false,
            emails: ['', '', ''],
            pastDenialReasons: [],
            isLoading: true
        }
    },
    components: {
        EmailModal,
        DenialModal
    },
    async mounted() {
        this.isLoading = true;

        const campaignIdFromRoute = this.$route.params.campaignId;
        if (campaignIdFromRoute) {
            this.campaignId = campaignIdFromRoute;
            localStorage.setItem('campaignId', campaignIdFromRoute);
        } else {
            this.campaignId = localStorage.getItem('campaignId');
        }
        console.log('campaignId that will be used: ' + this.campaignId);

        await Promise.all([
            this.fetchCampaignData(),
            this.fetchDenialData()
        ]);

        let user = await Auth.currentAuthenticatedUser();
        this.role = user.signInUserSession.idToken.payload['cognito:groups']?.[0];
        if (!this.role || this.role != 'approvers') {
            this.role = 'campaignPlanner'
        }
        this.isLoading = false;

        import('semantic-ui-css/semantic.min.js').then(() => {
            $(this.$refs.modal).modal({
                closable: false,
                onApprove: () => {
                    this.close()
                    return false
                }
            })
            $(this.$refs.modal).css('display', 'block');
        })
    },
    methods: {
        closeEmailModal() {
            console.log('close email modal was hit')
            this.showModal = false;
        },
        closeDenialModal() {
            this.showDenialModal = false;
        },
        async fetchCampaignData() {
            this.isLoading = true;

            this.campaignInfo = {
                campaigns: null
            };

            const user = await Auth.currentAuthenticatedUser();
            const userToken = user.signInUserSession.idToken.jwtToken;
            const endPoint = `${BASE_URL}campaigns/` + this.campaignId;

            try {
                const response = await fetch(endPoint, {
                    headers: {
                        'Authorization': userToken,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data)
                this.campaignInfo = data;
                console.log(this.campaignInfo);
            } catch (error) {
                console.error('error', error);
            } finally {
                this.isLoading = false;
            }
        },
        extractEmails() {
            let emails = [];
            this.campaignInfo.campaigns.response.forEach((campaignType) => {
                if (campaignType.campaign_type === 'General Scope') {
                    campaignType.response.forEach((response) => {
                        if (response.Question === 'Agency Email(s)') {
                            let emailArray = response.Value.split(',');
                            emails.push(...emailArray);
                        }
                    });
                }
            });

            if (emails.length === 0) {
                emails.push('');
            }

            return emails;
        },
        acceptCampaign() {
            this.emails = this.extractEmails();
            console.log(this.emails);
            this.showModal = true;
        },
        handleModalClose() {
            this.$refs.emailModal.close();
            this.showModal = false;
        },
        handleEmailSubmit(newEmails) {
            this.isLoading = true;
            console.log('handle Email Submit called')
            console.log(newEmails);
            this.emails = newEmails;
            this.closeEmailModal();
            this.handleSubmit(this.emails);

        },
        async handleSubmit(emails) {
            const user = await Auth.currentAuthenticatedUser();
            const userToken = user.signInUserSession.idToken.jwtToken;
            console.log(user);
            console.log(emails);
            let headers = {
                'Authorization': userToken,
                'Content-Type': 'application/json'
            }
            console.log(this.campaignId);
            const requestBody = {
                emails,
                id: this.campaignId
            };
            console.log(requestBody);

            try {
                let response = await axios.post(`${BASE_URL}campaigns/approve`, JSON.stringify(requestBody), { headers: headers });
                console.log(response.data)
                this.$store.commit('setSuccessMessage', 'The campaign was approved successfully.');
                setTimeout(() => {
                    this.$store.commit('clearSuccessMessage');
                }, 3000);
                this.$router.push({ name: 'CampaignDashboard' });
            } catch (error) {
                console.log('error', error)
            }
        },
        async denyCampaign() {
            this.showDenialModal = true;
            console.log(this.showDenialModal);
        },
        async handleDenial(reason) {
            this.isLoading = true;
            const user = await Auth.currentAuthenticatedUser().catch(err => {
                console.error('Error getting current user:', err);
            });
            console.log(user);
            console.log(reason);
            const userToken = user.signInUserSession.idToken.jwtToken;

            let requestBody = {
                id: this.campaignId,
                reason: reason
            }

            let url = `${BASE_URL}campaigns/deny`

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userToken, 
                },
                body: JSON.stringify(requestBody),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    this.closeDenialModal();
                    this.isLoading = false;
                    this.$store.commit('setSuccessMessage', 'The campaign was denied successfully.');
                    setTimeout(() => {
                        this.$store.commit('clearSuccessMessage');
                    }, 3000);
                    this.$router.push({ name: 'CampaignDashboard' });
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
        async fetchDenialData() {
            const user = await Auth.currentAuthenticatedUser();
            const userToken = user.signInUserSession.idToken.jwtToken;
            const endPoint = `${BASE_URL}denial_reasons/` + this.campaignId;

            try {
                const response = await fetch(endPoint, {
                    headers: {
                        'Authorization': userToken,
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data)
                this.pastDenialReasons = data.denial_reasons.map(item => {
                    const name = item.approver_name?.toString() || '';
                    const updatedOn = new Date(parseInt(item.updated_on) * 1000).toLocaleDateString() || '';
                    const reason = item.reason?.toString() || '';

                    return { name, updatedOn, reason };
                });
            } catch (error) {
                console.error('error', error);
            }
        },
        previousPage() {
            this.$router.push('/');
        },
        goHome() {
            this.$router.push({ name: 'CampaignDashboard' });
        },
        async signOut() {
            console.log('signoutmethod reached');
            try {
                this.$store.commit('clearSuccessMessage');
                await Auth.signOut();
                await this.$store.dispatch('resetCampaignState');  
                this.$router.push({ name: 'CampaignDashboard' });  
            } catch (error) {
                console.log("Sign Out Failed", error);
            }
        },
    },

};
</script>

<style scoped>
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #f3f3f3;
    margin-top: 0px;
    margin-bottom: 20px;
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

ul.custom-list {
    list-style-type: none !important;
    padding-left: 0 !important;
}
ul.custom-list li {
    text-align: left !important;
}


.campaign-summary {
    width: 70%;
}

.denial-reasons-container {
    width: 30%;
}
</style>
