import { createRouter, createWebHistory } from "vue-router";
import CampaignDashboard from '../components/CampaignDashboard.vue'
import CampaignGeneralScope from '../components/CampaignGeneralScope.vue'
import CampaignType from '../components/CampaignType.vue'
import Paid from '../components/PaidQuestions.vue'
import Influencer from '../components/InfluencerQuestions.vue'
import Organic from '../components/OrganicQuestions.vue'
import TestAndLearn from '../components/CampaignTest&Learn.vue'
import WrapUp from '../components/WrapUp.vue'
import CamapaignSummary from '../components/CampaignSummary.vue'
import QuestionEditor from '../components/QuestionEditor.vue'
import ReviewCampaign from '../components/ReviewCampaign.vue'

const routes = [
    {
        path:'/',
        name:'CampaignDashboard',
        component: CampaignDashboard
    },
    {
        path:'/general-scope',
        name:'CampaignGeneralScope',
        component: CampaignGeneralScope
    },
    {
        path:'/campaign-type',
        name:'CampaignType',
        component:CampaignType
    },
    {
        path:'/paid',
        name:'Paid',
        component:Paid
    },
    {
        path:'/influencer',
        name:'Influencer',
        component:Influencer
    },
    {
        path:'/organic',
        name:'Organic',
        component:Organic
    },
    {
        path:'/test-and-learn',
        name:'TestAndLearn',
        component:TestAndLearn
    },
    {
        path:'/wrap-up',
        name:'WrapUp',
        component:WrapUp
    },
    {
        path:'/campaign-summary',
        name:'CampaignSummary',
        component:CamapaignSummary
    },
    {
        path:'/question-editor',
        name:'QuestionEditor',
        component:QuestionEditor

    },
    {
        path:'/review-campaign',
        name:'ReviewCampaign',
        component:ReviewCampaign
    }
]

const router = createRouter({
    history:createWebHistory(process.env.BASE_URL), routes
})

export default router 